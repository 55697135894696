.TopSection {
    display: flex;
    position: relative;
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #989898;
}

.TopSection p {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
