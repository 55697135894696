.EachItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
}

.EachItem i {
    font-size: 20px;
    transform: translateY(4px);
}

.EachItem p {
    font-size: 18px;
    font-weight: 500;
}

.EachItemHide {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 20px;
    border-left: 1px solid #f0f0f0;
    cursor: pointer;
}

.EachItemHide i {
    font-size: 20px;
    transform: translateY(4px);
}

.EachItemHide p {
    display: none;
    font-size: 18px;
    font-weight: 500;
}

.ItemLink {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

.SubItem {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 20px;
    border-left: 1px solid #f0f0f0;
    cursor: pointer;
}

.SubItemLink {
    text-decoration: none;
    color: rgb(1, 59, 88);
}

.SubItem i {
    color: rgb(1, 59, 88);
    font-size: 25px;
    transform: translateY(4px);
    margin-right: 20px;
}

.SubItem p {
    font-size: 14px;
    font-weight: 400;
}