.MainNavBar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px; 
    background-color: rgb(255, 255, 255);
    min-height: 50px;
    width: 100%;
    z-index: 1000;
    border-bottom: 1px #c6c6c6 solid;
}

.LogoDiv {
    position: absolute;
    left: 10px;
    width: 140px;
    height: 50px;
}

.NavBarDiv {
    position: absolute;
    top: 20px;
    left: 180px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.EachMenu {
     margin-right: 15px;
     font-weight: 500;
     color: rgb(12, 19, 43);
}

.EachMenu:hover {
    color: #5096c4;
    cursor: pointer;
}

.EachMenu a{
    text-decoration: none;
    color: inherit;
    font-weight: 500;
    font-size: 20px;
}

.LoginPanelDiv {
    position: absolute;
    display: flex;
    right: 40px;
    width: 300px;
    justify-content: end;
}

.EachPanelItemDiv {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    margin-left: 20px;
}

.EachPanelItemDiv i{
    font-size: 22px;
    margin-right: 8px;
    transform: translateY(2px);
}

.EachPanelItemDiv p{
    font-size: 22px;
    font-weight: 500;
}

.EachPanelItemDiv a{
    text-decoration: none;
    color: inherit;
    font-size: 22px;
    font-weight: 500;
}

.EachPanelItemDiv:hover{
    cursor: pointer;
    color: #5096c4;
}

.LoginPanelSection {
    position: fixed;
    display: flex;
    right: 10px;
    padding: 10px 10px; 
    background-color: rgb(249, 207, 124);
    background-color: rgb(232, 232, 232);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    z-index: 1000;
    border-bottom: 1px #c6c6c6 solid;
    margin-top: 70px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.panelLeave {
    max-height: 0;
    opacity: 0;
    transition: all 0.3s ease;
}
.panelEnter {
    max-height: 300px; /* 或者你想要的高度 */
    opacity: 1;
    transition: all 0.3s ease;
}

.LoginBodyDiv_V2 {
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}

.BodyButton_V2 {
    position: absolute;
    right: 30px;
    bottom: 10px;
}



.BodyInput_V2 label{
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
}


.BodyButton_V2 button{
    background-color: #5096c4;
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 10px;
    border-radius: 5px;
    border: none;
}

.BodyInput_V2 input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 20px;
}

.BodyInput_V2 input[type="password"] {
    width: 97%;
    height: 18px;
}

.BodyReturnButton_V2 button{
    border: none;
    /* background-color: rgb(249, 207, 124); */
}

.AlertMessageDiv_V2 {
    display: flex;
    color: #c45050;
}


.AlertMessageDiv_V2 p{
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
}

.AlertMessageDiv_V2 i {
    margin-right: 3px;
    font-weight: 500;
    transform: translateY(4px);
}