.MainSection {
    padding-top: 80px;
}

.ProductDiv {
    width: 95%;
    margin: auto;
    margin-top: 20px;
}

.ProductDiv h1 {
    margin-bottom: 20px;
}

.ProductDiv hr {
    margin-bottom: 20px;
}

.ProductListDiv {
    width: 100%;
}

.EachProductTypeDiv {
    padding: 20px;
}

.EachProductBaseInfo {
    width: 90%;
    height: 35vh;
    margin: auto;
    display: flex;
    align-items: center;
    align-content: center;

    margin-bottom: 20px;
}



.EachProductBaseImage {
    position: relative;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.ProductTitleDiv {
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: center;
}

.EachProductBaseImage img {
    width: 250px;
    height: 250px;

}

.EachProductList {
    width: 100%;
    margin-left: 20px;
    padding: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: center;
    overflow-x: auto;
    /* 自動出現水平滾動條 */
    gap: 20px;
    /* 可選：增加內容之間間距，讓可滾動的效果更明顯 */
}

.BackButtonDiv {
    position: fixed;
    right: 120px;
    top: 180px;
    z-index: 9998;
    
}

.BackButtonDiv button {
    width: 85px;
    height: 42px;
    border-radius: 5px;
    background-color: #093d61;
    color: white;
    font-size: 20px;
    cursor: pointer;
    border: none;
    transform: translateY(-15px);
}

.EachProductList::-webkit-scrollbar {
    height: 8px;
}

.EachProductList::-webkit-scrollbar-thumb {
    background-color: #888;
    /* 滾動條顏色 */
    border-radius: 10px;
    /* 滾動條圓角 */
}

.EachProductInfoDiv {
    position: relative;
    height: 30vh;
    min-width: 35vw;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background-color: rgb(253, 252, 252);
}

.EachProductInfoDiv hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.EachProductDescDiv {
    width: 90%;
    margin: auto;
}

.EachProductDescDiv li {
    font-size: 20px;
}

.PriceDiv {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PriceDiv p{
    font-size: 20px;
    font-weight: bold;
    white-space: pre-line;
}


.ControlButtonDiv {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: end;
    width: 95%;
}

.ControlButtonDiv button {
    width: 100px;
    height: 45px;
    margin-left: 10px;
    border-radius: 5px;
    background-color: #5096c4;
    color: white;
    font-size: 20px;
    cursor: pointer;
    border: none;
}

.ControlButtonAddOn {
    width: 140px !important;
    background-color: #3a6a8b !important;
}

/* 
.EachProductTypeDiv {
    min-height: 35vh;
}

.EachProductBaseInfo {
    width: 85%;
    margin: auto;
    border: 2px black solid;
}

.EachProductList {
    width: 100%;
    display: flex;
}

.EachProductBaseImage img {
    width: 250px;
    height: 250px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
 */